export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const arrowDownIcon = require("../assets/arrowDownIcon.svg");
export const uploadIcon = require("../assets/uploadIcon.svg");
export const calendarIcon = require("../assets/calendarIcon.svg");
export const accordionIcon = require("../assets/accordionIcon.svg");
export const userIcon = require("../assets/profile-pic.png");
export const bustomerBG = require("../assets/customer-bg.png");
export const bgAccess = require("../assets/access-bg.jpg");
export const businessLogo = require("../assets/Business.svg");
export const rocketIcon = require("../assets/Rocket.svg");
export const bookMark = require("../assets/Bookmark.svg");
export const ExpandIcon = require("../assets/ExpandIcon.png");
export const fileUpload = require("../assets/prime_upload.svg");
export const documentUpload = require("../assets/document-upload.svg");
export const selectMenuIcon = require("../assets/select-menu-icon.svg");
export const calenderIcon = require("../assets/calender-icon.svg");
export const usersIcon = require("../assets/users.svg");
export const startUpIcon = require("../assets/startup.svg");
export const dot = require("../assets/dot.svg");
export const ExclamationIcon = require("../assets/exclamation.svg");
export const SuccessIcon = require("../assets/success.svg");
export const flagSme = require("../assets/flagSme.svg");
export const flagStartup = require("../assets/flagStartup.svg");
